
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __appWithI18n from 'next-translate/appWithI18n'
// @ts-ignore
    
import { ReactElement, useEffect } from "react";
import type { AppProps } from "next/app";
import PageWithLayoutType from "../types/pageWithLayouts";
import "antd/dist/antd.css";
import "../styles/globals.css";
import "../styles/search-filters.css";
import { RouteGuard } from "../components/guards/route-guard";
import Head from "next/head";
import { ScreenGuard } from "../components/guards/screen-guard";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { LEADSNOW_VERSION } from "../constants/app-metadata";
import { message } from "antd";
import { useRouter } from "next/router";
import Script from "next/script";

const CLIENT_ID = process.env.NEXT_PUBLIC_GOOGLE_SHEETS_CLIENT_ID!;

type AppLayoutProps = AppProps & {
  Component: PageWithLayoutType;
  pageProps: any;
};
function MyApp({ Component, pageProps }: AppLayoutProps) {
  const router = useRouter();

  // Alert message global config.
  message.config({
    duration: 2,
    maxCount: 3,
    rtl: true,
    top: 120,
  });

  const Layout =
    Component.layout || ((children: ReactElement) => <>{children}</>);

  // ----------------------------------------------------FB Pixel----------------------------------------------------
  useEffect(() => {
    import("react-facebook-pixel")
      .then((x) => x.default)
      .then((ReactPixel) => {
        ReactPixel.init(process.env.NEXT_PUBLIC_FB_PIXEL_ID!); // facebookPixelId
        ReactPixel.pageView();

        router.events.on("routeChangeComplete", () => {
          ReactPixel.pageView();
        });
      });
  }, [router.events]);
  // -----------------------------------------------------------------------------------------------------------------

  return (
    <>
      {/* ----------------------------------------------------Google anayltics---------------------------------------------------- */}
      <Script
        id="ga-script-1"
        strategy="lazyOnload"
        src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}`}
      />

      <Script id="ga-script-2" strategy="lazyOnload">
        {`
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}', {
        page_path: window.location.pathname,
        });
    `}
      </Script>
      {/* -------------------------------------------------------------------------------------------------------- */}

      <RouteGuard>
        <ScreenGuard>
          <Head>
            <title>Leadsnow - {LEADSNOW_VERSION}</title>
            <link rel="shortcut icon" href="/images/favicon.png" />

            {/* Preload fonts for better performance */}
            <link
              rel="preload"
              href="/fonts/Inter/Inter-Medium.ttf"
              as="font"
              crossOrigin=""
            />
            <link
              rel="preload"
              href="/fonts/Inter/Inter-Regular.ttf"
              as="font"
              crossOrigin=""
            />
          </Head>
          <Layout>
            <GoogleOAuthProvider clientId={CLIENT_ID}>
              <Component {...pageProps} />
            </GoogleOAuthProvider>
          </Layout>
        </ScreenGuard>
      </RouteGuard>
    </>
  );
}
const __Page_Next_Translate__ = MyApp;


// @ts-ignore
    export default __appWithI18n(__Page_Next_Translate__, {
// @ts-ignore
      ...__i18nConfig,
// @ts-ignore
      isLoader: true,
// @ts-ignore
      skipInitialProps: true,
// @ts-ignore
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
    });
// @ts-ignore
  